<template>

    <auth-content>
      <el-header :title="$t('modules.quest.title')" :titleSuffix="$t('modules.quest.subtitle')" slot="header">
        <div slot="actions">
          <el-header-action
            icon="arrow-right8"
            :label="firstLevelStarted ? $t('modules.quest.pick_content') : $t('modules.quest.start_content')"
            @click.native.prevent="getNextCard()"
          />
        </div>
      </el-header>

      <div class="magicquest-wrapper" v-if="questProgress">

          <div
            v-for="quest in quests"
            :key="quest.id"
          >

            <div
              :class="{
                'card card-content magicquest-lvl': true,
                ['magicquest-lvl-' + quest.id]: true,
              }"
            >

              <div class="card-header cursor-pointer" @click="quest.open = !quest.open">
                <div class="row" v-if="questProgress && questProgress[quest.id]">
                  <div class="col-xxl-4 col-lg-6 mb-3 mb-lg-0">
                    <el-card-title :title="$t('modules.quest.level.descriptions.' + quest.id + '.title')" />
                    <div class="text-secondary">{{ $t('modules.quest.level.descriptions.' + quest.id + '.subtitle') }}</div>
                  </div>
                  <div class="col-xxl-8 col-lg-6">
                    <i :class="{
                      'icon-arrow-down12': !quest.open,
                      'icon-arrow-up12': quest.open,
                      'icon-arrow-down12 float-right text-secondary h3 m-0': true,
                    }"></i>
                    <div v-if="questProgress[quest.id]['global'] > 0" class="progress-counter mr-1 font-weight-bold float-left h4 mb-0">
                      <div class="process-counter-text text-info">
                        {{ questProgress[quest.id]['global'] || 0 }}
                        <span class="small">%</span>
                      </div>
                    </div>
                    <div class="progress-wrapper" v-if="questProgress[quest.id]['global'] > 0">
                      <div class="progress-bar">
                        <div class="progress-bar-inner" :style="'width: ' + questProgress[quest.id]['global'] + '%; background-color: ' + quest.color"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" v-if="quest.open">
                <div class="row">
                  <div class="col-xxl-4 col-lg-6 mb-3 mb-lg-0">
                    <div class="text-center">
                      <el-chart-radar :class="{'chart': true, 'chart-empty': quest.id != 1 && (!questProgress[quest.id]['global'] || questProgress[quest.id]['global'] == 0)}" :data="questProgressChartData(quest.id)" />
                    </div>
                  </div>
                  <div class="col-xxl-8 col-lg-6">
                    <div class="text-center mb-3" v-if="quest.id == firstLevelToFinish">
                      <div
                        class="btn font-weight-semibold btn-success"
                        @click="getNextCard()"
                      ><i class="icon-arrow-right8" /> {{ firstLevelStarted ? $t('modules.quest.pick_content') : $t('modules.quest.start_content') }}</div>
                    </div>
                    <div v-if="$te('modules.quest.level.descriptions.' + quest.id + '.paragraphs')" v-for="(p, pIndex) in $t('modules.quest.level.descriptions.' + quest.id + '.paragraphs')">
                      <p :class="'paragraph-desc paragraph-desc-' + pIndex" v-if="(pIndex == 1 || quest.desc) && p && p != ''">
                        {{ p }}
                        <span
                          @click="quest.desc = true"
                          class="font-weight-bold cursor-pointer text-uppercase small text-underline text-primary"
                          v-if="$t('modules.quest.level.descriptions.' + quest.id + '.paragraphs')[1] && pIndex == 1 && !quest.desc"
                        >{{ $t('common.see_more') }}</span>
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <ul class="list-desc" v-if="$te('modules.quest.level.descriptions.' + quest.id + '.list')">
                          <fragment v-for="p in $t('modules.quest.level.descriptions.' + quest.id + '.list')">
                            <li v-if="p && p != ''"><span class="text-info mr-1">—</span> {{ p }}</li>
                          </fragment>
                        </ul>
                      </div>
                      <div class="col-md-6" v-if="questProgress[quest.id].details.totalSheets > 0">
                        <div class="mb-1 text-info">
                          <span class="font-weight-bold">{{ questProgress[quest.id].details.totalSheets }}</span>
                          {{ $tc('modules.quest.level.content', questProgress[quest.id].details.totalSheets) }} :
                        </div>
                        <ul class="list-desc">
                          <fragment v-for="p in questProgress[quest.id].details.types">
                            <li v-if="p && p != ''">
                              <span class="text-info mr-1">—</span>
                              <span class="text-secondary font-weight-bold">{{ p.sheets_count }}</span>
                              {{ pluralize(p.type, p.sheets_count) }}
                              <small class="font-weight-bold text-success" v-if="p.completed / p.sheets_count * 100 > 0">{{ p.completed / p.sheets_count * 100 | round }}{{ $t('modules.quest.level.type_realized') }}</small>
                            </li>
                          </fragment>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>


      </div>

      <el-modal
        size="sm"
        id="quest-next"
        class="next"
        :title="card ? $t('modules.quest.level.descriptions.' + card.level + '.title') : ''"
      >

        <div>
          <div class="" v-if="card && card.id && !cardLimitReached">
            <div class="category text-right small text-secondary font-weight-semibold">
              {{ $t('modules.quest.categories.' + card.category) }}
            </div>
            <div class="deckcard-content text-center row mt-2 align-items-center">
              <div class="col">
                <h3 class="title font-weight-semibold">{{ card.name }}</h3>
                <div class="desc">{{ card.short_description }}</div>
              </div>
            </div>
            <div v-if="card.type != 'Attraction'">
              <div class="row mt-3 align-items-center">
                <div class="col-6">
                  <div class="btn btn-card-action btn-outline-danger border-dashed w-100" @click="answerCard(card, 'REFUSED')">{{ $t('modules.quest.refuse_content') }}</div>
                </div>
                <div class="col-6">
                  <div class="btn btn-card-action btn-success w-100 text-white font-weight-semibold" @click="answerCard(card, 'ACCEPTED')">{{ $t('modules.quest.accept_content') }}</div>
                </div>
              </div>
              <div class="text-center mt-3">
                <span class="mt-1 cursor-pointer text-grey small font-weight-semibold" @click="answerCard(card, 'POSTPONED')">{{ $t('modules.quest.later_content') }}</span>
              </div>
            </div>
            <div class="mt-4" v-else>
              <div class="btn btn-success w-100 text-white font-weight-bold" @click="answerCard(card, 'ACCEPTED')">{{ $t('modules.quest.unlock_attraction') }}</div>
            </div>
          </div>
          <div class="p-3" v-if="cardLimitReached">
            <div class="deckcard-content text-center row mt-4 align-items-center">
              <div class="col">
                <h3 class="title">{{ $t('modules.quest.card_limit_reached.title') }}</h3>
                <div class="desc">{{ $t('modules.quest.card_limit_reached.description') }}</div>
              </div>
            </div>
          </div>
          <div class="p-3" v-if="card && !card.id && !cardLimitReached">
            <div class="deckcard-content text-center row mt-4 align-items-center">
              <div class="col">
                <h3 class="title">{{ $t('modules.quest.no_content.title') }}</h3>
                <div class="desc">{{ $t('modules.quest.no_content.description') }}</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div v-if="!card && !cardLimitReached">
            <div class="loader text-center">
              <loader />
            </div>
          </div>
        </div>

      </el-modal> <!-- next -->

    </auth-content>

</template>

<script>

import store from '@/store'
import {
  QUEST_PROGRESS_REQUEST,
  QUEST_NEXT_CARD_REQUEST,
  QUEST_ANSWER_CARD,
} from '../mutations-types'

import { WORLD_MODULES, WORLD_REQUEST } from '@/store/mutations-types'

export default {
  name: 'QuestIndex',
  data () {
    return {
      cardLimitReached: false,
      activeLevel: false,
      activeLevelModal: false,
      hoverCard: false,
      loading: false,
      questProgress: false,
      card: false,
      quests: {
        1: {
          id: 1,
          color: '#9ac659',
          desc: false,
          open: false,
        },
        2: {
          id: 2,
          color: '#7ec099',
          desc: false,
          open: false,
        },
        3: {
          id: 3,
          color: '#36ace6',
          desc: false,
          open: false,
        },
        4: {
          id: 4,
          color: '#1ea3a4',
          desc: false,
          open: false,
        },
        5: {
          id: 5,
          color: '#f17e31',
          desc: false,
          open: false,
        }
      },
    }
  },
  mounted () {
    if (this.$attrs.openCardOnLoad && this.$attrs.openCardOnLoad === true) {
      setTimeout(() => {
        this.getNextCard()
      }, 500)
    }
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld'];
    },
    modules () {
      return this.$store.getters['world/getModules'];
    },
    firstLevelStarted () {
      return this.questProgress && this.questProgress[1].global > 0
    },
    firstLevelToFinish () {
      if (!this.questProgress) {
        return null
      }
      let firstLevelToFinish = 1
      _.forEach(this.questProgress, (progress, level) => {
        if (progress.global > 0 && progress.global < 100) {
          firstLevelToFinish = level
          return false // break iteration
        }
      })
      return firstLevelToFinish
    },
  },
  methods: {
    loadModules () {
      this.$store.dispatch('world/' + WORLD_REQUEST, this.world.id)
    },
    openLevel (id) {
      this.activeLevelModal = false

      this.activeLevel = {
        'id': id,
      }
      this.openModal('quest-level-progress')
      setTimeout(() => {
        window.dispatchEvent(new Event('resize')) // trigger canvas chat resize
        this.activeLevelModal = true
      }, 500)
    },
    resetCard () {
      this.card = false
    },
    getNextCard () {
      this.openModal("quest-next")
      this.getCard()
    },
    getCard () {
      this.$store.dispatch('quest/' + QUEST_NEXT_CARD_REQUEST, this.$route.params.world).then(card => {
        this.card = {...card}
      }).catch(error => {
        if (
          error.response.data.errors &&
          error.response.data.errors.error &&
          error.response.data.errors.error[0] &&
          error.response.data.errors.error[0] == "world_daily_limit_reached"
        ) {
          this.cardLimitReached = true
        }
      })
    },
    getProgress () {
      this.$store.dispatch('quest/' + QUEST_PROGRESS_REQUEST, this.$route.params.world).then(progress => {
        this.questProgress = {...progress}
        _.forEach(this.questProgress, (levelProgress, levelId) => {
          if (levelProgress.global > 0 && levelProgress.global < 100) {
            this.quests[levelId].open = true
          }
          if (levelProgress.global == 0 && levelId == 1) {
            this.quests[levelId].open = true
          }
        })
      })
    },
    answerCard (sheet, response) {
      this.resetCard()
      this.$store.dispatch('quest/' + QUEST_ANSWER_CARD, {
        worldId: this.$route.params.world,
        sheetId: sheet.id,
        status: response,
      }).then(sheetStatus => {
        if (sheetStatus.status === "ACCEPTED") {
          if (sheet.type == 'Attraction') {
            this.loadModules()
            // redirect to attraction index
            if (
              this.$router.resolve({
                name: sheet.attraction_id + '.index',
                params: {
                  world: this.world.id
                }
              }).href != '/'
            ) {
              this.$router.push({
                name: sheet.attraction_id + '.index',
                params: {
                  world: this.world.id ,
                },
              })
            } else {
              this.$store.dispatch('world/' + WORLD_MODULES, this.world.id).then(() => {
                this.$router.push({
                  name: 'module.index',
                  params: {
                    world: this.world.id,
                    module: sheet.attraction_id,
                    page: Object.keys(this.$store.getters['world/getModules'][sheet.attraction_id].pages)[0],
                  },
                })
              })
            }
          } else {
            // go to wiki
            this.$router.push({name: 'wiki.content', params: { world: this.$route.params.world, content: sheetStatus.data.content_id}})
          }
        } else if (sheetStatus.status === "REFUSED") {
          this.getCard()
          this.getProgress()
        } else if (sheetStatus.status === "POSTPONED") {
          this.getCard()
        }
      })
      .catch(error => {
        this.notifError(error)
        this.getCard()
      })
    },
    questProgressChartData (levelId) {
      if (!this.questProgress || !levelId) {
        return null
      }

      let chartData = {
        "axes": [],
        "values": [
          {
            "title": this.$t('modules.quest.level.progress'),
            "value": []
          },
        ]
      }

      Object.keys(this.questProgress[levelId]['categories']).forEach(category => {
        chartData.axes.push({'title': this.$t('modules.quest.categories.' + category)})
        if (levelId == 1 && this.questProgress[levelId].global == 0) {
          // Fake data
          chartData.values[0].value.push(_.random(40, 80))
        } else {
          // Real data
          chartData.values[0].value.push(this.questProgress[levelId]['categories'][category])
        }
      })
      chartData.values[0].value = chartData.values[0].value.join(',')

      return chartData
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getProgress()
    })
  },
}
</script>

<style lang="scss" scoped>

.progress-wrapper {
  transition: all .3s ease;
  margin-top: 8px;
  max-width: 100%;
  width: 80%;
  padding: 2px;
  border-radius: 40px;
  background: black;
  height: 15px;
  max-height: 1vw;
  min-height: 8px;
  position: relative;
  display: inline-block;
  .progress-bar {
    overflow: visible;
    position: relative;
    width: 100%;
    height: 100%;
    background: none;
    .progress-bar-inner {
      box-shadow: inset 0 4px rgba(255, 255, 255, .14);
      position: absolute;
      border-radius: 40px;
      height: 100%;
      min-width: 11px;
      .progress-counter {
        font-weight: bold;
        font-size: 1.3em;
        text-align: center;
        .process-counter-text {
        }
      }
    }
  }
}

.chart {
  transition: all .2s ease;
}
.chart-empty {
  opacity: 0.3;
}

.list-desc,
.paragraph-desc {
  font-size: 0.9em;
}

.list-desc {
  list-style-type: none;
  padding: 0;
  li {
    text-indent: -1.4em;
    padding-left: 1.4em;
    line-height: 1.4em;
    margin-bottom: 2px;
  }
}

.paragraph-desc-1 {
  font-weight: 500;
}


.btn-card-action {
  white-space: normal;
}
</style>
